<template>
  <div class="manuscripts">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <ManuscriptSelect
            @select="selectManuscript"
            :manuscript="manuscriptObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            id="sigle-comment"
            label="Single comment:"
            label-for="single-comment"
          >
            <b-form-input
              id="single-comment"
              v-model="sigleComment"
              placeholder="Single comment"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="ordering"
            label="Ordering:"
            label-for="ordering"
          >
            <b-form-input
              id="ordering"
              v-model="ordering"
              placeholder="Ordering"
            ></b-form-input>
          </b-form-group>

          <b-form-checkbox
            v-model="isBaseManuscript"
            size="sm"
            class="mb-4"
            name="check-button"
            switch
          >
            Base Manuscript
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ManuscriptSelect from "@/components/Bibliography/ManuscriptSelect.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "ManuscriptAutocompleteEdition",
  props: ["exclude", "manuscript", "orderingNum"],
  data() {
    return {
      query: "",
      timer: null,
      manuscriptObj: this.manuscript,
      isBaseManuscript: this.manuscript ? this.manuscript.isBaseManuscript : false,
      ordering: this.manuscript ? this.manuscript.ordering : (this.orderingNum ? this.orderingNum : 1),
      sigleComment: this.manuscript ? this.manuscript.sigleComment : '',
      index: this.manuscript ? this.manuscript._index : null,
    };
  },
  components: {
    ManuscriptSelect,
  },
  methods: {
    selectManuscript(manuscript) {
      this.manuscriptObj = manuscript;
    },
  },
  updated(){
    this.manuscriptObj['isBaseManuscript'] = this.isBaseManuscript;
    this.manuscriptObj['ordering'] = this.ordering;
    this.manuscriptObj['sigleComment'] = this.sigleComment;
    this.manuscriptObj['_index'] = this.index;
    HelpStore.item = this.manuscriptObj
  }
};
</script>
