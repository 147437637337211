<template>
  <div class="editionDetails">
    <div v-if="loading || !edition">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle
              v-if="edition"
              v-bind:title="edition.sigleLegam"
              v-bind:subtitle="edition.liability"
              :pretitle="this.$t('EditionDetails')" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.edition.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.edition.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button v-if="!$store.getters.checkRole('legam.bib.edition.modify') && $store.getters.checkRole('legam.bib.edition.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <div class="mb-4" v-if="config.editable">
                <p class="mb-1">BIB Labels</p>
                <BibLabelSelect v-if="editMode" :selected="edition.biblabels.map(biblabel => biblabel.id)" @select="selectBibLabels" />
                <span v-else>
                  <b-badge v-for="biblabel in edition.biblabels" :key="biblabel.id" href="#" class="mr-1" variant="secondary">{{ biblabel.label }}</b-badge>
                </span>
              </div>
              <b-row>
                <b-col>
                  <h6>{{ $t('SigleLEGaM') }}</h6>
                  <b-form-input
                    id="sigle-legam"
                    v-model="edition.sigleLegam"
                    placeholder="Sigle LEGaM"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="edition.sigleLegam"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('Liability') }}</h6>
                  <b-form-select
                    v-model="edition.liability"
                    name="liability-select"
                    :options="editionLiability"
                    v-if="editMode"
                    class="mb-4">
                  </b-form-select>
                  <span v-else v-html="edition.liability" class="liabilityClass"></span>
                  <!-- <h6>Alternative Sigles</h6>
                  <div
                    v-for="siglesource in edition.siglesources"
                    :key="siglesource.siglesourceCode"
                    v-html="`${siglesource.alternativeSigle} - ${siglesource.siglesourceCode}`"
                  ></div> -->
                </b-col>
                <b-col>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>Bib Description</h6>
                  <b-form-textarea
                    id="bib-description"
                    placeholder="BIB Description"
                    v-model="edition.bibDescription"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="edition.bibDescription"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('CommentGeneral')}}</h6>
                  <b-form-textarea
                    id="comment-general"
                    placeholder="Comment General"
                    v-model="edition.commentGeneral"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="edition.commentGeneral"></span>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>{{ $t('Transcription') }}</h6>
                  <b-form-textarea
                    id="transcription"
                    placeholder="Transcription"
                    v-model="edition.transcription"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="edition.transcription"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('Responsibility') }}</h6>
                  <b-form-textarea
                    id="responsibility"
                    placeholder="Responsibility"
                    v-model="edition.responsibility"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="edition.responsibility"></span>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>{{ $t('FormerEdition') }}</h6>
                  <b-form-textarea
                    id="former-edition"
                    placeholder="Former Edition"
                    v-model="edition.formerEdition"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="edition.formerEdition"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('FormerDescription') }}</h6>
                  <b-form-textarea
                    id="former-description"
                    placeholder="Former Description"
                    v-model="edition.formerDescription"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="edition.formerDescription"></span>
                </b-col>
              </b-row>

              <b-button @click="showUnstructData = !showUnstructData" size="sm" class="mb-2 mt-4" v-if="edition.unstruct && config.editable">
                <span v-if="showUnstructData">Hide unstruct data</span>
                <span v-else>Show unstruct data</span>
              </b-button>

              <div v-show="showUnstructData" v-if="edition.unstruct">
                <h6 class="with-line mt-4">Unstruct</h6>
                <b-row cols="2">
                  <b-col v-for="key in Object.keys(filteredUnstructData)" :key="key">
                    <h6>{{ key.replace(/_/ig, ' ') }}</h6>
                    <pre>{{ edition.unstruct[key] }}</pre>
                  </b-col>
                </b-row>
              </div>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="text-left">
                      Scholars <small>({{ edition.authors.length }} {{ edition.authors.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" visible accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-author>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-author" title="Add scholar" ok-only ok-variant="secondary" size="lg">
                        <AuthorAutocomplete @select="addAuthor" />
                      </b-modal>
                      <AuthorTable
                        :showTotal="false"
                        :authors="edition.authors"
                        @delete="deleteAuthor"
                        :editMode="editMode"
                        v-if="edition.authors.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Manuscripts <small>({{ edition.manuscripts.length }} {{ edition.manuscripts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                          <b-icon icon="plus"></b-icon>
                          Add
                        </span>
                        <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" @ok="addManuscript" size="lg">
                          <ManuscriptAutocompleteEdition :manuscript="modalManuscript" :orderingNum="edition.manuscripts.length + 1" />
                        </b-modal>
                        <ManuscriptTable
                          :showTotal="false"
                          :manuscripts="edition.manuscripts"
                          :showEditionManuscript="true"
                          @delete="deleteManuscript"
                          @edit="editManuscript"
                          :editMode="editMode"
                          v-if="edition.manuscripts.length"
                          :key="indexManuscript"
                        />
                        <span v-else>No data</span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2a class="text-left">
                      Reviews <small>({{ edition.reviews.length }} {{ edition.reviews.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2a" accordion="my-accordion-2a" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-review>
                          <b-icon icon="plus"></b-icon>
                          Add
                        </span>
                        <b-modal id="modal-add-review" title="Add review" ok-only ok-variant="secondary" size="lg">
                          <ReviewAutocomplete @select="addReview" />
                        </b-modal>
                        <ReviewTable
                          :showTotal="false"
                          :reviews="edition.reviews"
                          @delete="deleteReview"
                          :editMode="editMode"
                          v-if="edition.reviews.length"
                        />
                        <span v-else>No data</span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 class="text-left">
                      Texts <small>({{ edition.texts.length }} {{ edition.texts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-text>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-text" title="Add text" ok-only ok-variant="secondary" @ok="addText" size="lg">
                        <TextAutocompleteEdition :text="modalText" />
                      </b-modal>
                      <TextTable
                        :showTotal="false"
                        :texts="edition.texts"
                        :showEditionText="true"
                        @delete="deleteText"
                        @edit="editText"
                        :editMode="editMode"
                        v-if="edition.texts.length"
                        :key="indexText"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 class="text-left">
                      Studies <small>({{ edition.studies.length }} {{ edition.studies.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                          <b-icon icon="plus"></b-icon>
                          Add
                        </span>
                        <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                          <StudyAutocomplete @select="addStudy" />
                        </b-modal>
                        <StudyTable
                          :showTotal="false"
                          :studies="edition.studies"
                          @delete="deleteStudy"
                          :editMode="editMode"
                          v-if="edition.studies.length"
                        />
                        <span v-else>No data</span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-6 class="text-left">
                      Alternative Sigles <small>({{ edition.siglesources.length }} {{ edition.siglesources.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-6" accordion="my-accordion-6" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-siglesource>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-siglesource" title="Add alternative sigle" ok-only ok-variant="secondary" @ok="addSigleSource" size="lg">
                        <SigleSourceRelAutocomplete :sigleSourceRel="modalSigleSourceRel" />
                      </b-modal>
                      <SigleSourceRelTable
                        :showTotal="false"
                        :siglesourcesRel="edition.siglesources"
                        @delete="deleteSigleSource"
                        @edit="editSigleSource"
                        :editMode="editMode"
                        v-if="edition.siglesources.length"
                        :key="indexSigleSource"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'edition'" :objectId="editionId" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>


<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ManuscriptTable from '@/components/Bibliography/ManuscriptTable.vue'
  import TextTable from '@/components/Bibliography/TextTable.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import StudyTable from '@/components/Bibliography/StudyTable.vue'
  import AuthorTable from '@/components/Bibliography/AuthorTable.vue'
  import ReviewTable from '@/components/Bibliography/ReviewTable.vue'
  import AuthorAutocomplete from '@/components/Bibliography/AuthorAutocomplete.vue'
  import ReviewAutocomplete from '@/components/Bibliography/ReviewAutocomplete.vue'
  import ManuscriptAutocompleteEdition from '@/components/Bibliography/ManuscriptAutocompleteEdition.vue'
  import TextAutocompleteEdition from '@/components/Bibliography/TextAutocompleteEdition.vue'
  import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
  import BibLabelSelect from '@/components/Bibliography/BibLabelSelect.vue'
  import SigleSourceRelAutocomplete from '@/components/Bibliography/SigleSourceRelAutocomplete.vue'
  import SigleSourceRelTable from '@/components/Bibliography/SigleSourceRelTable.vue'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'EditionDetails',
    data() {
      return {
        editionId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        indexManuscript: 0,
        indexText: 0,
        showUnstructData: false,
        modalManuscript: null,
        modalText: null,
        indexSigleSource: 0,
        modalSigleSourceRel: null,
        editionLiability: ['certain', 'uncertain', 'external'],
        config: config,
      }
    },
    components: {
      PageTitle,
      ManuscriptTable,
      TextTable,
      StudyTable,
      AuthorTable,
      ReviewTable,
      AuthorAutocomplete,
      ReviewAutocomplete,
      ManuscriptAutocompleteEdition,
      StudyAutocomplete,
      ChangeLogsTable,
      BibLabelSelect,
      TextAutocompleteEdition,
      SigleSourceRelAutocomplete,
      SigleSourceRelTable,
    },
    computed: {
      ...mapState({
        edition: state => state.edition.edition
      }),
      filteredUnstructData(){
        let retval = {}
        Object.keys(this.edition.unstruct).forEach(key => {
          if (key != 'unused_attributes'){
            retval[key] = this.edition.unstruct[key]
          }
        })
        return retval
      }
    },
    mounted() {
      this.$store.dispatch('loadEdition', this.editionId)
    },
    watch: {
      'editionId'() {
        this.loading = true
        this.$store.dispatch('loadEdition', this.editionId)
      },
      'edition'() {
        this.loading = false
      }
    },
    methods: {
      addAuthor(author){
        this.$bvModal.hide('modal-add-author')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Author added`
        })
        this.edition.authors.push(author)
      },
      deleteAuthor(authorId){
        this.edition.authors = this.edition.authors.filter(author => author.id != authorId)
      },
      addReview(review){
        this.$bvModal.hide('modal-add-review')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Review added`
        })
        this.edition.reviews.push(review)
      },
      deleteReview(reviewId){
        this.edition.reviews = this.edition.reviews.filter(review => review.id != reviewId)
      },
      addManuscript(){
        this.modalManuscript = null;
        if (HelpStore.item){
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Manuscript added`,
          })

          let isBase = HelpStore.item.isBaseManuscript;
          let baseIndex = -1;

          if (HelpStore.item._index != null){
            this.edition.manuscripts[HelpStore.item._index] = HelpStore.item;
            baseIndex = HelpStore.item._index;
          }
          else {
            this.edition.manuscripts.push(HelpStore.item);
            baseIndex = this.edition.manuscripts.length - 1;
          }

          if (isBase){
            for (let manuscript of this.edition.manuscripts){
              if (manuscript._index != baseIndex){
                manuscript.isBaseManuscript = false;
              }
            }
          }
          this.indexManuscript++;
        }
      },
      editManuscript(manuscript){
        this.modalManuscript = manuscript;
        this.$bvModal.show('modal-add-manuscript');
      },
      selectBibLabels(biblabels){
        this.edition['biblabels'] = biblabels
      },
      deleteManuscript(manuscriptId){
        this.edition.manuscripts = this.edition.manuscripts.filter(
          manuscript => manuscript.id != manuscriptId
        )
      },
      addText(){
        this.modalText = null;
        if (HelpStore.item){
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Text added`,
          })

          if (HelpStore.item._index != null){
            this.edition.texts[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.edition.texts.push(HelpStore.item);
          }

          this.indexText++;
        }
      },
      editText(text){
        this.modalText = text;
        this.$bvModal.show('modal-add-text');
      },
      deleteText(textId){
        this.edition.texts = this.edition.texts.filter(text => text.id != textId)
      },
      addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Study added`
        })
        this.edition.studies.push(study)
      },
      deleteStudy(studyId){
        this.edition.studies = this.edition.studies.filter(study => study.id != studyId)
      },
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadEdition', this.editionId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveEdition', this.edition).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      addSigleSource(){
        this.modalSigleSourceRel = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.edition.siglesources[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.edition.siglesources.push(HelpStore.item);
          }
          this.indexSigleSource++;
        }
      },
      deleteSigleSource(sigleSourceRelId){
        this.edition.siglesources = this.edition.siglesources.filter(sigleSourceRel => sigleSourceRel.id != sigleSourceRelId)
      },
      editSigleSource(sigleSourceRel){
        this.modalSigleSourceRel = sigleSourceRel;
        this.$bvModal.show('modal-add-siglesource');
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteEdition', this.edition).then(() => {
              router.push('/editions')
            })
          }
        })
      }
    }
  }
</script>
