<template>
  <div class="texts">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <TextSelect
            @select="select"
            :text="textObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            id="modern-paging"
            label="Modern paging:"
            label-for="modern-paging"
          >
            <b-form-input
              id="modern-paging"
              v-model="modernPaging"
              placeholder="Modern paging"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TextSelect from "@/components/Bibliography/TextSelect.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "TextAutocompleteEdition",
  props: ["exclude", "text"],
  data() {
    return {
      query: "",
      timer: null,
      textObj: this.text,
      modernPaging: this.text ? this.text.modernPaging : 0,
      index: this.text ? this.text._index : null,
    };
  },
  components: {
    TextSelect,
  },
  methods: {
    select(text) {
      this.textObj = text;
    },
  },
  updated(){
    this.textObj['modernPaging'] = this.modernPaging;
    this.textObj['_index'] = this.index;
    HelpStore.item = this.textObj
  }
};
</script>
